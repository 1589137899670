import { Col, Field, Row, SearchInput, SearchPanel } from '@elotech/components';
import React, { useEffect } from 'react';

type Props = {
  field?: Field;
  onSearch: (searchValue?: string) => void;
  textButton?: string;
  disableInitialSearch?: boolean;
  disableCharValidation?: boolean;
};

const defaultField: Field = {
  label: '',
  name: '',
  type: 'STRING'
};
const BasicSearchFilter: React.FC<Props> = ({
  field = defaultField,
  onSearch,
  textButton = 'Filtrar',
  disableInitialSearch = false,
  disableCharValidation = false
}) => {
  const searchValueRef = React.createRef<any>();
  const onFilter = () => onSearch(searchValueRef.current.getValue());

  useEffect(() => {
    if (!disableInitialSearch) {
      onSearch();
    }
  }, []);

  return (
    <SearchPanel>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <SearchInput
              ref={searchValueRef}
              onEnter={onFilter}
              field={field}
              autoFocus={false}
              disableCharValidation={disableCharValidation}
            />
            <button className="btn filter" type="button" onClick={onFilter}>
              {textButton}
            </button>
          </div>
        </Col>
      </Row>
    </SearchPanel>
  );
};

export default BasicSearchFilter;
