import { KeycloakService } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Usuario, UsuarioLogin, UsuarioProvider, UsuarioSessao } from '../type';

const realmUrl = () =>
  `${KeycloakService.getInstance().authServerUrl}/realms/${
    KeycloakService.getInstance().realm
  }`;

export const getUserData = (): AxiosPromise<any> =>
  axios.get(`/painel-api/api/usuario/me`);

export const editUser = (userData: object): AxiosPromise<any> =>
  axios.put(`${realmUrl()}/usuario/me`, userData);

export const userExists = (): AxiosPromise<any> =>
  axios.get(`/gateway/public/user-exists`);

export const getUserProviders = (
  userId: string
): AxiosPromise<UsuarioProvider> =>
  axios.get(`/painel-api/api/usuario/${userId}/providers`);

export const removeProviderLinkFromUser = (
  userId: string,
  provider: string
): AxiosPromise<void> =>
  axios.delete(`${realmUrl()}/usuario/providers/${userId}/${provider}`);

export const getCurrentUserSessions = (): AxiosPromise<UsuarioSessao[]> =>
  axios.get(`${realmUrl()}/usuario/me/sessions`);

export const logoutCurrentUserSession = (
  idSessao: string
): AxiosPromise<void> =>
  axios.delete(`${realmUrl()}/usuario/me/session/${idSessao}`);

export const resetPassword = (usuario: Usuario): AxiosPromise<any> =>
  axios.put(`${realmUrl()}/usuario/${usuario.id}/reset-password/`, usuario);

export const getCurrentUserLastLogins = (): AxiosPromise<UsuarioLogin[]> =>
  axios.get(`${realmUrl()}/usuario/me/login`);

export const getUsuarios = (search: string): AxiosPromise<Usuario[]> =>
  axios.get(`${realmUrl()}/usuario?search=${search}`);

export const copyUserPemissons = (
  userIdFrom: string,
  userIdTo: string
): AxiosPromise<void> => {
  return axios.post(`/painel-api/api/usuario/copy-user-permissions`, {
    userIdFrom,
    userIdTo
  });
};

export const findById = (userId: string): AxiosPromise<Usuario> =>
  axios.get(`/painel-api/api/usuario/${userId}`);
